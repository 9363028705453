<template>
  <v-app-bar
    app
    color="background"
    extended
    extension-height="16"
    class="pt-2"
    elevate-on-scroll
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="toggleDrawer" />

    <v-container class="mx-auto py-0">
      <v-row align="center">
        <router-link to="/" custom v-slot="{ navigate }">
          <v-img
            @click="navigate"
            :src="require('@/assets/images/strataggem_logo.svg')"
            class="clickable mr-5"
            contain
            height="48"
            :width="logo_img_width"
            :max-width="logo_img_width"
          />
        </router-link>

        <v-spacer />

        <div
          class="d-inline-block"
          v-for="(link, i) in links"
          :key="i"
          v-bind="link"
        >
          <v-menu v-if="hasSubmenu(link)" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :large="$vuetify.breakpoint.lgAndUp"
                class="hidden-sm-and-down text-none font-large"
                :class="{
                  accent: 'company' == link.name,
                  'v-btn--active': isCurrentOrChildPage(link),
                }"
                text
              >
                {{ $t("global.pages_titles." + link.name) }}
                <v-icon small class="ml-1">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(subLink, index_sub) in link.children"
                :key="index_sub"
                link
                :to="subLink.href + '#content'"
              >
                <div>
                  <v-list-item-title>{{
                    $t("global.pages_titles." + subLink.name)
                  }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-else-if="'home' != link.name"
            :to="link.href + '#content'"
            :large="$vuetify.breakpoint.lgAndUp"
            class="hidden-sm-and-down text-none font-large"
            text
          >
            {{ $t("global.pages_titles." + link.name) }}
          </v-btn>

          <!-- Home button. Delete it when more than 4 or 5 buttons are already present (to save the space). -->
          <v-btn
            v-else-if="'home' == link.name"
            to="/"
            :large="$vuetify.breakpoint.lgAndUp"
            class="hidden-sm-and-down text-none font-large"
            text
          >
            {{ $t("global.pages_titles." + link.name) }}
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CoreAppBar",

  computed: {
    ...mapGetters(["links"]),

    logo_img_width() {
      if (this.$vuetify.breakpoint.width > 400) {
        return 222;
      } else if (this.$vuetify.breakpoint.width > 250) {
        return 150;
      } else {
        return 100;
      }
    },
  },

  methods: {
    ...mapMutations(["toggleDrawer"]),

    hasSubmenu(link) {
      return null != link.children && !link.hide_children_in_app_bar;
    },

    isCurrentOrChildPage(link) {
      // Does the menu button in question (link) correspond to the current open page?
      if (this.$route.name == link.name) return true;

      // Or is this menu button a parent of a the current open page?
      let pathArray = this.$route.path.split("/");
      if (pathArray.length > 2 && link.name === pathArray[1]) return true;

      // or is it an unrelated page ?
      return false;
    },
  },
};
</script>

<style scoped>
.v-app-bar .v-btn.accent {
  color: black;
}

.v-app-bar .v-btn {
  margin: 0 0.1em;
}

.locale-changer {
  width: 4em;
}
</style>