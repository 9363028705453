var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"pt-2",attrs:{"app":"","color":"background","extended":"","extension-height":"16","elevate-on-scroll":""}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",on:{"click":_vm.toggleDrawer}}),_c('v-container',{staticClass:"mx-auto py-0"},[_c('v-row',{attrs:{"align":"center"}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('v-img',{staticClass:"clickable mr-5",attrs:{"src":require('@/assets/images/strataggem_logo.svg'),"contain":"","height":"48","width":_vm.logo_img_width,"max-width":_vm.logo_img_width},on:{"click":navigate}})]}}])}),_c('v-spacer'),_vm._l((_vm.links),function(link,i){return _c('div',_vm._b({key:i,staticClass:"d-inline-block"},'div',link,false),[(_vm.hasSubmenu(link))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down text-none font-large",class:{
                accent: 'company' == link.name,
                'v-btn--active': _vm.isCurrentOrChildPage(link),
              },attrs:{"large":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("global.pages_titles." + link.name))+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',_vm._l((link.children),function(subLink,index_sub){return _c('v-list-item',{key:index_sub,attrs:{"link":"","to":subLink.href + '#content'}},[_c('div',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("global.pages_titles." + subLink.name)))])],1)])}),1)],1):('home' != link.name)?_c('v-btn',{staticClass:"hidden-sm-and-down text-none font-large",attrs:{"to":link.href + '#content',"large":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},[_vm._v(" "+_vm._s(_vm.$t("global.pages_titles." + link.name))+" ")]):('home' == link.name)?_c('v-btn',{staticClass:"hidden-sm-and-down text-none font-large",attrs:{"to":"/","large":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},[_vm._v(" "+_vm._s(_vm.$t("global.pages_titles." + link.name))+" ")]):_vm._e()],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }